<script setup lang="ts">
const state = defineModel<boolean>({ default: false });

let width = 1920;

if (import.meta.client) {
  width = window.innerWidth;
}

// Watch the state to toggle the 'popup-open' class on the body
watch(state, (newVal) => {
  if (width < 1200) {
    if (newVal) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  }
});

const onClose = () => {
  state.value = false;
};
</script>

<template>
  <transition name="fade">
    <div v-if="state" class="popup-wrapper">
      <div class="popup-content">
        <slot/>
      </div>
      <div class="overlay" @click="onClose" />
    </div>
  </transition>
</template>

<style scoped lang="scss">
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--bg-popup);
  backdrop-filter: blur(2px);
  z-index: 12;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;
}

@media (max-width: 1200px) {
  .popup-content {
    width: 100%;
    max-width: calc(100vw - 48px);
    max-height: calc(100vh - 48px);
    overflow-y: auto;
  }
}
</style>

<style>
body.popup-open {
  overflow: hidden;
}
</style>
