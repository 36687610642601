export const truncateMiddle = (str: string, chars: number = 8): string => {
  if (!str) {
    return '';
  }
  
  if (str.length <= chars * 2) {
    return str;
  }

  const start = str.substring(0, chars);
  const end = str.substring(str.length - chars);
  return `${start}...${end}`;
};
