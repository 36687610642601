import { getLocalStorageItem } from '@/utilities/helpers/browser';

const USER_WALLET = 'user-wallet';

export const useMetaMaskStore = defineStore('meta-mask', () => {
  const openMetaMaskPopup = ref(false);
  const metaMaskUserWallet = ref('');

  const isPolygonAddedtoWallet = ref(false);
  const isWalletChangedToPolygon = ref(false);
  const isTokenAddedtoWallet = ref(false);

  let localStorageAccount = null;

  const errorMessage = ref(false);

  onMounted(() => {
    localStorageAccount = getLocalStorageItem(USER_WALLET);
    metaMaskUserWallet.value = localStorageAccount ?? '';
  });

  function toogleMetaMaskPopup() {
    openMetaMaskPopup.value = !openMetaMaskPopup.value;
  }

  function setMetaMaskUserWallet(address: string) {
    metaMaskUserWallet.value = address;
    localStorage.setItem(USER_WALLET, address);
  }

  function clearMetaMaskUserWallet() {
    localStorage.removeItem(USER_WALLET);
    localStorage.clear();
    metaMaskUserWallet.value = '';
  }

  return {
    openMetaMaskPopup,
    metaMaskUserWallet,
    isPolygonAddedtoWallet,
    isTokenAddedtoWallet,
    isWalletChangedToPolygon,
    errorMessage,
    toogleMetaMaskPopup,
    setMetaMaskUserWallet,
    clearMetaMaskUserWallet,
  };
});
