import { ref } from 'vue';
import { MetaMaskSDK } from '@metamask/sdk';
import { useMetaMaskStore } from '~/stores/meta-mask.store';

let MMSDK = null;
if (import.meta.client) {
  MMSDK = new MetaMaskSDK({
    dappMetadata: {
      name: 'Newtify exchange page',
      url: window.location.href,
    },
  });
}

const useMetaMask = () => {
  const account = ref(null);

  const metaMaskStore = useMetaMaskStore();

  const getEthereumProvider = () => {
    if (!MMSDK) {
      console.error('MMSDK is not initialized');
      return null;
    }
    const ethereum = MMSDK.getProvider();
    if (!ethereum) {
      console.error('MMSDK is not initialized');
      return null;
    }
    return ethereum;
  };

  const getUserWallet = async () => {
    try {
      metaMaskStore.errorMessage = false;
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }
      const accounts: any = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      if (accounts && accounts.length > 0) {
        account.value = accounts[0];
        metaMaskStore.setMetaMaskUserWallet(accounts[0]);
        await importToken();
        metaMaskStore.toogleMetaMaskPopup();
      } else {
        console.error('No accounts found');
      }
    } catch (error) {
      metaMaskStore.errorMessage = true;
      console.error(error);
    }
  };

  const disconnectMetaMask = async () => {
    account.value = null;
    metaMaskStore.clearMetaMaskUserWallet();
    metaMaskStore.toogleMetaMaskPopup();
    if (window.ethereum) {
      await window.ethereum.request({
        method: 'wallet_revokePermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
    } else {
      console.error('MetaMask is not installed or enabled');
    }
  };

  const importToken = async () => {
    try {
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }

      const tokenAddress = '0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE';
      const tokenSymbol = 'NF';
      const tokenDecimals = 6;
      const tokenImage = 'https://www.newtify.io/apple.png';
      const chainId = '0x89'; // Polygon network chain ID
      const chainName = 'Polygon Mainnet';
      const rpcUrls = ['https://polygon-rpc.com/'];
      const blockExplorerUrls = ['https://polygonscan.com/'];
      const nativeCurrency = {
        name: 'POL',
        symbol: 'POL',
        decimals: 18,
      };

      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              rpcUrls: rpcUrls,
              // blockExplorerUrls: blockExplorerUrls,
              nativeCurrency: nativeCurrency,
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }

      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });

      const result = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const connectAndGetUserWallet = async () => {
    try {
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }
      const accounts: any = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      if (accounts && accounts.length > 0) {
        account.value = accounts[0];
        metaMaskStore.setMetaMaskUserWallet(accounts[0]);
      } else {
        console.error('No accounts found');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addPolygonToUserWallet = async () => {
    try {
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }
      const chainId = '0x89';
      const chainName = 'Polygon Mainnet';
      const rpcUrls = ['https://polygon-rpc.com/'];
      const blockExplorerUrls = ['https://polygonscan.com/'];
      const nativeCurrency = {
        name: 'POL',
        symbol: 'POL',
        decimals: 18,
      };
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainId,
            chainName: chainName,
            rpcUrls: rpcUrls,
            blockExplorerUrls: blockExplorerUrls,
            nativeCurrency: nativeCurrency,
          },
        ],
      });
      metaMaskStore.isPolygonAddedtoWallet = true;
    } catch (error) {
      console.error(error);
    }
  };

  const changeUserWalletBlockChain = async () => {
    try {
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }
      const chainId = '0x89';

      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
      metaMaskStore.isWalletChangedToPolygon = true;
    } catch (error) {
      console.error(error);
    }
  };

  const addCustomToken = async () => {
    try {
      const ethereum = getEthereumProvider();
      if (!ethereum) {
        return;
      }

      const tokenAddress = '0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE';
      const tokenSymbol = 'NF';
      const tokenDecimals = 6;
      const tokenImage = 'https://www.newtify.io/apple.png';

      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      metaMaskStore.isTokenAddedtoWallet = true;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getUserWallet,
    disconnectMetaMask,
    importToken,
    connectAndGetUserWallet,
    addPolygonToUserWallet,
    changeUserWalletBlockChain,
    addCustomToken,
    account,
  };
};

export default useMetaMask;
