<script setup lang="ts">
import useUtmParams from '~/composables/use-utm-params';

const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});

interface Emits {
  (e: 'close'): void;
}
const emit = defineEmits<Emits>();
</script>

<template>
  <div class="popup-get-app">
    <h2 class="font--h3">Get the Newtify app</h2>
    <div class="popup-get-app-image-wrapper" style="display: none">
      <nuxt-img
        src="/v2/qr-template.png"
        width="144"
        height="144"
        class="qr-code-image"
      ></nuxt-img>
    </div>
    <div class="download-buttons-wrapper">
      <nuxt-link
        to="https://apps.apple.com/us/app/newtify-io/id6482849312"
        class="download-button hover-scale-light"
        target="_blank"
      >
        <Icon size="48" name="icons:app-store-button" class="" />
      </nuxt-link>
      <nuxt-link
        :to="googlePlayUrl"
        class="download-button hover-scale-light"
        target="_blank"
      >
        <Icon size="48" name="icons:play-store-button" class="" />
      </nuxt-link>
    </div>
    <button class="popup-close-button" @click="emit('close')">
      <base-icon
        name="base/close_gray"
        size="32px"
        color="var(--grey-300)"
      ></base-icon>
    </button>
  </div>
</template>

<style scoped lang="scss">
.popup-get-app {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  padding: 40px;
}

.popup-get-app-image-wrapper {
  padding: 8px;
  border-radius: 16px;
  background: var(--primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.popup-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
}
</style>
